<script lang="ts">
  import { fade } from 'svelte/transition';

  export let min = 1;
  export let max = 10;
  export let value = 5;

  $: steps = new Array(max - min + 1).fill(0).map((_, idx) => idx + min);

  function getColorClass(step: number, value: number): string {
    return step <= value ? 'color' : 'gray';
  }
</script>

<div class="rating-container">
  {#each steps as step (step) }
  <span
    class="rating w-full {getColorClass(step, value)}"
    transition:fade={{ duration: 1000 }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" viewBox="0 0 20 20" fill="currentColor">
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  </span>
  {/each}
</div>

<style>
  .rating-container {
    display: flex;
    justify-content: space-between;
  }
  .rating.w-full {
    width: 100%;
  }
  .rating.color svg {
    fill: #ffcd3c;
  }
  .rating.gray svg {
    fill: #666;
  }
</style>