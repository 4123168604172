<script lang="ts">
  import type { IReview, StarRating } from "../models";
  import Rating from './Rating.svelte';

  export let review: IReview;

  const ratingToValue: Record<StarRating, number> = {
    FIVE: 5,
    FOUR: 4,
    THREE: 3,
    TWO: 2,
    ONE: 1,
    STAR_RATING_UNSPECIFIED: 0,
  };

  function formatDate(date: string): string {
    const d = new Date(date);
    const withPadding = (n: number) => n.toString().padStart(2, '0');
    return `${withPadding(d.getDate())}/${withPadding(d.getMonth() + 1)}/${d.getFullYear()}`;
  }
</script>

<div class="review-container">
  <div class="left">
    <img src={review.reviewer.profilePhotoUrl} alt="" />
  </div>
  <div class="right">
    <div class="reviewer-name">{review.reviewer.displayName}</div>
    <div class="rating-with-date">
      <div class="rating">
        <Rating min={1} max={5} value={ratingToValue[review.starRating]} />
      </div>
      <div class="date">le {formatDate(review.createTime)}</div>
    </div>
    <div class="body">{review.comment}</div>
  </div>
</div>

<style>
  .review-container {
    display: flex;
    flex-direction: row;
    height: 15rem;
    overflow: auto;
  }
  .left {
    width: 10%;
    max-width: 3rem;
    margin-right: 1rem;
  }
  .left img {
    width: 100%;
  }
  .right {
    flex: 1;
  }
  .reviewer-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    line-height: normal;
  }

  .rating-with-date {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .rating {
    flex: 1;
    max-width: 7rem;
    margin-right: 1rem;
  }

  .date {
    flex: 1;
    color: #70757a;
    font-size: 1rem;
  }

  .body {
    white-space: pre-line;
    line-height: 1.5;
    margin-top: 0.5rem;
  }
</style>