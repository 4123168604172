<script lang="ts">
  import { onDestroy } from 'svelte';

  import Review from './components/Review.svelte';
  import type { IReviewsResponse } from './models';

  const googlePlaceId = 'ChIJ_ctAzuzftBIRKzgL6rQGCYY';

  const reviewsP = fetch(`${BASE_API_URL}/api/google/reviews`)
    .then(res => res.json())
    .then((res: IReviewsResponse) => {
      startInterval(res.reviews.length);
      return res;
    })

  let selectedIndex = 0;
  let interval: number = null;

  function startInterval(length) {
    interval = setInterval(() => {
      selectedIndex = (selectedIndex + 1) % length;
    }, 4000);
  }

  function stopInterval() {
    if (interval) {
      clearInterval(interval);
      interval = null;
    }
  }

  onDestroy(() => stopInterval());

  function next() {
    selectedIndex++;
    stopInterval();
  }

  function prev() {
    selectedIndex--;
    stopInterval();
  }
</script>

<h3>Avis clients</h3>

<div class="separator"></div>

{#await reviewsP}
	<p>...</p>
{:then response}
  <div class="card">
    {#if selectedIndex > 0}
    <div class="arrow left-arrow" on:click={() => prev()}>
      <span>&lt;</span>
    </div>
    {/if}
    <div class="card-content">
      <Review review={response.reviews[selectedIndex]} />
    </div>
    <a class="display-all" target="blank" rel="noopener noreferrer" href="https://search.google.com/local/reviews?placeid={googlePlaceId}">
      Voir tous les avis sur Google
    </a>
    {#if selectedIndex < response.reviews.length - 1}
    <div class="arrow right-arrow" on:click={() => next()}>
      <span>&gt;</span>
    </div>
    {/if}
  </div>
{:catch error}
	<p style="color: red">{error.message}</p>
{/await}

<style>
  h3 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
  .separator {
    border-top: 2px solid #4dcfff;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
  }
  .card {
    position: relative;
    border: 1px solid #ccc;
    margin-bottom: 2rem;
  }
  .card-content {
    padding: 2rem;
  }

  @media (max-width: 480px) {
    .card-content {
      padding: 1rem;
    }
  }

  .card .arrow {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    font-size: 3rem;
    color: #4dcfff;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }
  .card .left-arrow {
    left: -40px;
  }
  .card .right-arrow {
    right: -40px;
  }
  .card .display-all {
    background-color: #4dcfff;
    color: white;
    font-family: "Arvo";
    display: block;
    padding: 1rem 2rem;
    text-decoration: none;
    font-size: 1.15rem;
    position: relative;;
  }
</style>
